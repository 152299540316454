import React from "react";

export default () => {
  return (
    <div className="hang-on">
      <img className="spinner" src='/favicon.ico' alt=""/>
      <p className="cage-chat">
        Well, Baby O, it’s not exactly mai-tais and Yahtzee out here — but let’s do it.
      </p>
    </div>
  )
};
